export const __ServicesListLang = {
    filters: 'فیلتر ها',
    newest: 'جدیدترین',
    oldest: 'قدیمی ترین',
    most_visited: 'پربازدید ترین',
    most_popular: 'محبوب ترین',
    mostPriority: 'متخب',
    maximum_cashback: 'بیشترین بازگشت وجه',
    ordering: 'مرتب سازی',
    result: 'نتیجه',
    about: 'درباره',
    sale: 'فروش',
    view_brand: 'مشاهده برند',
    popularBrands: 'برندهای پرطرفدار',
    viewAll: 'مشاهده همه',
    errorMessage: 'درخواست با خطا مواجه شد.',
    empty: 'خدمتی با فیلتر مورد نظر یافت نشد.',
    initializeError: 'انتخاب با خطا مواجه شد.',
    initializeSuccess: 'پیش سفارش شما با موفقیت ثبت شد',
    servicesTile: 'خرید و خدمات',
};
