import React, { useContext, useState } from 'react';
import styles from './header.module.scss';
import { GlobalContext } from 'context';
import { LanguageEnum, ServiceTypeEnum } from 'types';
import { usePathname } from 'next/navigation';
import { Button, Dropdown } from 'antd';
import { useEventListener } from 'scripts';
import { TbWorld, TbMenu2, TbUserCircle, TbChevronDown } from 'react-icons/tb';
import DrawerComp from './drawer/drawer.index';
import logoFA from 'assets/images/logo-typo-fa.svg';
import logoEN from 'assets/images/logo-typo-en.svg';
import Image from 'next/image';
import { PATH } from 'data';
import { AppLinkComp } from '..';

export default function HeaderComp(props: { hasSlug?: boolean }) {
    const { langText, profile } = useContext(GlobalContext);
    const lang = langText.header;
    const language = langText.lang;
    const pathname = usePathname();
    const [collapse, setCollapse] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const isPage = (path: string) => pathname.split('/').join('').split('?')[0] === path.split('/').join('');
    const isHome = isPage(PATH(language).home());
    const isServicePage = isPage(PATH(language).services.index());
    const isDating = isPage(PATH(language).dating);
    const isLink = (item: ServiceTypeEnum) =>
        !(
            isHome ||
            (isServicePage && item === ServiceTypeEnum.offer) ||
            (isDating && item === ServiceTypeEnum.dating && profile)
        );
    const nextLang = () => {
        const nextL = language === LanguageEnum.fa ? LanguageEnum.en : LanguageEnum.fa;
        return {
            fontFamily: nextL === LanguageEnum.fa ? 'AnjomanMax' : 'AnjomanMax',
            link: `\\${nextL}` + (props.hasSlug ? '' : pathname.slice(3)),
        };
    };

    const CSServices = [
        { title: lang.cacheBack, id: ServiceTypeEnum.dating, className: profile ? 'd-none' : '' },
        { title: lang.search, id: ServiceTypeEnum.housing },
        { title: lang.jobSearch, id: ServiceTypeEnum.events },
    ];

    useEventListener('scroll', () => {
        if (window.scrollY > 0) setCollapse(true);
        else setCollapse(false);
    });

    return (
        <header className={`${styles['tehranto-header']} ${collapse ? styles['collapsed'] : ''}`}>
            <DrawerComp open={open} onClose={() => setOpen(false)} />
            <div className="h-100 main-content gap-16 justify-content-between align-items-center">
                <AppLinkComp href={PATH(language).home()} searchParamsSensitive={false}>
                    <h1 className="t-h3 t-primary align-items-center gap-8">
                        <Image src={language === LanguageEnum.fa ? logoFA : logoEN} alt="TehranTo" height={40} />
                        {/* {lang.tehranto} */}
                    </h1>
                </AppLinkComp>
                <div className={`align-items-center d-pre-md-none ${styles['links']}`}>
                    <AppLinkComp
                        className={`${isHome ? 't-primary t-500' : ''}`}
                        href={PATH(language).home()}
                        searchParamsSensitive={false}
                        preventReload={isHome}
                    >
                        {lang.home}
                    </AppLinkComp>
                    <AppLinkComp
                        href={PATH(language).services.index()}
                        className={`${isServicePage ? 't-primary t-500' : ''}`}
                        preventReload={isServicePage}
                    >
                        {lang.services}
                    </AppLinkComp>
                    <AppLinkComp
                        href={PATH(language).dating}
                        className={`${isDating ? 't-primary t-500' : ''} ${profile ? '' : 'd-none'}`}
                        preventReload={isDating}
                    >
                        {lang.cacheBack}
                    </AppLinkComp>
                    {CSServices.map((item, key) => (
                        <AppLinkComp
                            key={key}
                            href={PATH(language).home(item.id)}
                            searchParamsSensitive={false}
                            preventReload={!isLink(item.id)}
                            className={item.className ?? ''}
                        >
                            {item.title}
                        </AppLinkComp>
                    ))}
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <AppLinkComp
                                            className={`${pathname.split('/').join('') === PATH(language).blog.index().split('/').join('') ? 't-primary-i t-500' : ''}`}
                                            href={PATH(language).blog.index()}
                                        >
                                            {lang.blog}
                                        </AppLinkComp>
                                    ),
                                    key: '0',
                                },
                                {
                                    label: (
                                        <AppLinkComp
                                            className={`${pathname.split('/').join('') === PATH(language).aboutUs.split('/').join('') ? 't-primary-i t-500' : ''}`}
                                            href={PATH(language).aboutUs}
                                        >
                                            {lang.aboutUs}
                                        </AppLinkComp>
                                    ),
                                    key: '1',
                                },
                            ],
                        }}
                    >
                        <span className="align-items-center gap-8">
                            {lang.others}
                            <TbChevronDown />
                        </span>
                    </Dropdown>
                </div>
                <div className={`align-items-center d-pre-md-none ${styles['tools']}`}>
                    <a href={nextLang().link} className="gap-8 t-400">
                        <TbWorld size={20} />
                        <span style={{ fontFamily: nextLang().fontFamily }}>{lang.otherLang}</span>
                    </a>
                    <div className={`gap-8`}>
                        {profile ? (
                            <AppLinkComp href={PATH(language).panel.index}>
                                <Button
                                    size="small"
                                    type="primary"
                                    className="center-content-y d-flex-i"
                                    icon={<TbUserCircle size={20} />}
                                >
                                    {profile.first_name ? profile.first_name : lang.profile}
                                </Button>
                            </AppLinkComp>
                        ) : (
                            <AppLinkComp href={PATH(language).login()}>
                                <Button className="p-0-i" size="small">
                                    <span className="ph-3">
                                        {lang.login}/{lang.signup}
                                    </span>
                                </Button>
                            </AppLinkComp>
                        )}
                    </div>
                </div>
                <Button
                    type="primary"
                    className="d-md-none-i"
                    size="small"
                    style={{ width: 45 }}
                    onClick={() => setOpen(true)}
                    aria-label="menu bar"
                >
                    <TbMenu2 size={20} />
                </Button>
            </div>
        </header>
    );
}
