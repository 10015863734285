import { useContext } from 'react';
import styles from './footer.module.scss';
import { GlobalContext } from '@/context';
import Image from 'next/image';
import logoFA from 'assets/images/logo-typo-fa-white.svg';
import logoEN from 'assets/images/logo-typo-en-white.svg';
import {
    TbBrandFacebook,
    TbBrandInstagram,
    TbBrandLinkedin,
    TbBrandTelegram,
    TbBrandTiktok,
    TbBrandWhatsapp,
    TbBrandX,
    TbBrandYoutube,
} from 'react-icons/tb';
import { PATH } from 'data';
import { AppLinkComp } from '..';
import { usePathname } from 'next/navigation';
import { LanguageEnum } from '@/types';

export default function FooterComp() {
    const langText = useContext(GlobalContext).langText;
    const lang = langText.header;
    const pathname = usePathname();

    return (
        <footer className={`${styles['tehranto-footer']} flex-column`}>
            <div className="flex-1 w-100 main-content gap-16 justify-content-between align-items-center flex-pre-md-column pv-6">
                <AppLinkComp href={PATH(langText.lang).home()}>
                    <h1 className="t-h3 t-white align-items-center gap-8">
                        <Image
                            src={langText.lang === LanguageEnum.en ? logoEN : logoFA}
                            alt="TehranTo"
                            // width={36}
                            height={40}
                        />
                        {/* {lang.tehranto} */}
                    </h1>
                </AppLinkComp>
                <div className={`center-content flex-wrap ${styles['links']}`}>
                    <AppLinkComp
                        className={`t-white ${pathname.split('/').join('').split('#')[0] === PATH(langText.lang).home().split('/').join('') ? 't-600' : ''}`}
                        href={PATH(langText.lang).home()}
                    >
                        {lang.home}
                    </AppLinkComp>
                    <AppLinkComp
                        className={`t-white ${pathname.split('/').join('').startsWith(PATH(langText.lang).services.index().split('/').join('')) ? 't-600' : ''}`}
                        href={PATH(langText.lang).services.index()}
                    >
                        {lang.services}
                    </AppLinkComp>
                    <AppLinkComp className="t-white" href={'#'}>
                        {lang.cacheBack}
                    </AppLinkComp>
                    <AppLinkComp className="t-white" href={'#'}>
                        {lang.jobSearch}
                    </AppLinkComp>
                    <AppLinkComp className="t-white" href={'#'}>
                        {lang.search}
                    </AppLinkComp>
                    <AppLinkComp
                        className={`t-white ${pathname.split('/').join('') === PATH(langText.lang).aboutUs.split('/').join('') ? 't-600' : ''}`}
                        href={PATH(langText.lang).aboutUs}
                    >
                        {lang.aboutUs}
                    </AppLinkComp>
                </div>
            </div>
            <div className="gap-24 main-content w-100 justify-content-center align-items-center mb-2 flex-wrap">
                <a aria-label="Whatsapp" className="t-white" target="_blank" href="https://wa.me/14169009821">
                    <TbBrandWhatsapp size={28} />
                </a>
                <a
                    aria-label="Instagram"
                    className="t-white"
                    target="_blank"
                    href="https://www.instagram.com/tehranto.club"
                >
                    <TbBrandInstagram size={28} />
                </a>
                <a aria-label="TikTok" className="t-white" target="_blank" href="https://www.tiktok.com/@tehranto.club">
                    <TbBrandTiktok size={28} />
                </a>
                <a
                    aria-label="Facebook"
                    className="t-white"
                    target="_blank"
                    href="https://www.facebook.com/tehranto.club"
                >
                    <TbBrandFacebook size={28} />
                </a>
                <a
                    aria-label="Youtube"
                    className="t-white"
                    target="_blank"
                    href="https://www.youtube.com/@tehrantoclub"
                >
                    <TbBrandYoutube size={28} />
                </a>
                <a aria-label="Telegram" className="t-white" target="_blank" href="https://t.me/tehranto_club">
                    <TbBrandTelegram size={28} />
                </a>
                <a
                    aria-label="Linkedin"
                    className="t-white"
                    target="_blank"
                    href="https://www.linkedin.com/company/tehrantoclub"
                >
                    <TbBrandLinkedin size={28} />
                </a>
                <a aria-label="X" className="t-white" target="_blank" href="https://twitter.com/tehrantoclub">
                    <TbBrandX size={28} />
                </a>
            </div>
            <div className={`center-content ${styles['rights']}`}>
                <p className="t-white">© 2024 Tehranto. All rights reserved.</p>
            </div>
        </footer>
    );
}
